import React, { useState, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import { registerBoard } from "../../provider/board";

function FeedbackModal({ showBox, closeModal }) {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [isEmail, setEmail] = useState(1);
  const [fileName, setFileName] = useState("");
  const [type, setType] = useState("");

  // Handlers
  const handleSubject = useCallback((e) => {
    setSubject(e.target.value);
  }, []);
  const handleContent = useCallback((e) => {
    setContent(e.target.value);
  }, []);
  const handleCheckout = (e) => {
    setEmail(e.target.checked ? 1 : null);
  };
  const handleRegister = () => {
    registerBoard(type, subject, content, isEmail, fileName);
  };

  if (!showBox) return null;

  return (
    <Box className="customer_feedback_box">
      <Box className="customer_feedback_bg" onClick={closeModal}></Box>
      <Box className="customer_feedback_content">
        <div className="center-body">
          <h1>고객제안(VOC)</h1>
          <h3>
            파츠고는 고객님의 소중한 의견을 반영하여
            <br />더 나은 서비스를 만들어 가겠습니다.
          </h3>
          <div className="service_view_body">
            <span>
              <b>■</b>제목
            </span>
            <input
              className="service_qna_write_title"
              placeholder="제목을 입력해 주세요."
              value={subject}
              onChange={handleSubject}
            />
            <span className="customer_feedback_cont_title">
              <b>■</b>내용
            </span>
            <div className="service_view_body_cont">
              <textarea
                className="service_qna_write_content"
                placeholder="고객님의 개선 아이디어, 제안, 불편사항 등 생생한 목소리를 들려주세요."
                value={content}
                onChange={handleContent}
              />
            </div>
            <div className="customer_feedback_chk_agree">
              <div>
                <input
                  type="checkbox"
                  className="write-checkbox"
                  checked={isEmail === 1}
                  onChange={handleCheckout}
                />
                <label style={{ marginLeft: "5px" }}>이메일로 답변받기</label>
              </div>
            </div>
            <div className="customer_feedback_btn">
              <div>
                <button
                  className="customer_feedback_submit"
                  onClick={handleRegister}
                >
                  등록
                </button>
                <button
                  className="customer_feedback_submit"
                  onClick={closeModal}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default FeedbackModal;
